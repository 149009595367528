@use '../../_variables.scss' as color;

.ai-wrapper {
    width: 80%;
    margin: auto;
    padding-bottom: 5rem;
    .ai-input {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .ai-input-text {
            width: 100%;
            height: 5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 16px;
            text-indent: .5rem;
            &:focus {
                outline: none;
            }
        }
        .ai-input-button {
            width: 100%;
            height: 3rem;
            border: none;
            border-radius: 5px;
            background-color: #1e90ff;
            color: white;
            font-size: 16px;
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }
    }
    .subtitle-ai {
        color: darkgray;
    }
    .skeleton-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        padding-top: 2rem;
        .skeleton-reasoning, .skeleton-stats {
            display: flex;
            flex-direction: column;
            gap: .5rem;
        }
    }
}

.gradient-text {
    background: -webkit-linear-gradient(color.$gradient-one, color.$gradient-two);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.toggle-mode {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 20%;
    @media (max-width: 576px) {
        width: 100%;
    }
}