.main-wrapper {
    display: flex;
    flex-grow: inherit;
    gap: 4rem;
}

.suggestions-wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 90%;
    margin: auto;
    .articles-title {
        padding-top: 1.5rem;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: .5rem;
    }
    hr {
        outline: none;
        border: 1px solid rgba(0,0,0,.06);
    }
}

.none {
    width: 100vh;
    font-size: 18px;
    font-weight: 600;
}

.article-wrapper {
    display: flex;
    justify-content: left;
    align-items: left;
}

.filter-wrapper-all {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    height: 50px;
    .filter-date {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 2rem;
    }
}

@media screen and (max-width: 866px) {
    .filter-wrapper-all {
        display: flex;
        flex-direction: row;
        align-items: center;
        float: left;
        margin: auto;
        height: 40px;
    }
    .filter-date {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;
        float: left;
        margin: 0;
    }
    .search-wrapper {
        height: 40px;
    }
    .main-wrapper {
        margin-top: 15%;
        position: relative;
    }
}

@media screen and (max-width: 480px) {
    .filter-date {
        display: flex;
        flex-direction: row;
        align-items: center;
        float: left;
        margin-top: auto;
        margin-bottom: auto;
        height: 100%;
    }
    .filter-items {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;
        float: left;
        margin: 0;
    }
    .main-wrapper {
        margin-top: 25%;
        position: relative;
    }
}

@media screen and (max-width: 430px) {
    .filter-date {
        display: flex;
        flex-direction: column;
        align-items: center;
        float: left;
        margin-top: auto;
        margin-bottom: auto;
        height: 100%;
        width: 80px !important;
    }
    .filter-items {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;
        float: left;
        margin: 0;
    }
    .main-wrapper {
        margin-top: 25%;
        position: relative;
    }
}

@media screen and (max-width: 400px) {
    .filter-wrapper-all {
        display: flex;
        flex-direction: column;
        align-items: left !important;
        float: left;
        margin-right: 0;
        height: 50px;
        gap: 2rem;
        .filter-date {
            display: flex;
            justify-content: left;
            align-items: left;
            margin-right: 60%;
        }
    }
   
    .filter-items {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;
        float: left;
        margin: 0;
    }
    .search-wrapper-blog {
        height: 40px;
    }
    .main-wrapper {
        margin-top: 40%;
        position: relative;
    }
}


@media screen and (max-width: 450px) {
    .search-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 100%;
    }
}

.right-content-article {
    width: 100%;
    @media screen and (max-width: 450px) {
        display: none;
    }   
}
