@use '../../_variables.scss' as color;

.about-wrapper {
    padding-bottom: 2rem;
    .about {
        width: 100%;
        position: relative;
        text-align: center;
    }
    .about-image {
        display: flex;
        justify-content: center;
        align-items: center;
        .about-image-inside {
            width: 100%;
            object-fit: cover;
            height: 50vh;
            filter: grayscale(.5) brightness(.6);
        }
    }
    .about-header {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px;
        font-weight: 600;
        color: white;
        .gradient-text {
            font-size: 35px;
            background: -webkit-linear-gradient(color.$gradient-one, color.$gradient-two);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .about-contents {
        padding-top: 2rem;
        width: 80%;
        text-align: left;
        margin: auto;
    }
    .bold {
        font-weight: 600;
    }
}