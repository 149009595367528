@use '../../_variables.scss' as color;
@use '../../index.scss' as main;


.writer-register-wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.verification-header {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
}

.login-header {
    font-size: 25px;
    font-weight: 700;
    text-align: left;
}
.login-subheader {
    font-size: 15px;
    font-weight: 500;
    color: darkgray;
}
.validation-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .email {
        width: 500px;
        padding-top: .5rem;
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }
    .message {
        padding-top: .5rem;
        display: block;
        flex-direction: column;
        padding-top: 1rem;
    }
}

textarea {
    margin-top: .5rem;
    font-family: 'Inter', sans-serif;
    width: 100%;
    outline: none;
    height: 100px;
    text-indent: .5rem;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    padding-top: .5rem;
}

.submit-verification {
    width: 100%;
    margin-top: 1rem;
}

.validating {
    height: 20px !important;
    width: 20px !important;
}

.error-text {
    padding-top: .5rem;
    color: darkred;
    font-size: 12px;
}

.success { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .success-icon {
        font-size: 100px !important;
        color: color.$gradient-one;
    }
    .success-message {
        width: 50%;
        font-size: 25px;
        font-weight: 600;
    }
}