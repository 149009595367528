@use '../../_variables.scss' as color;
@use '../../index.scss' as main;

.seller-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 60%;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    @media (max-width: 768px) {
        width: 90%;
    }
    input {
        font-size: 14px;
    }
    .form-content {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
    .seller-input {
        width: 50%;
        @media (max-width: 768px) {
            width: 90%;
        }
        border-radius: 5px;
        height: 40px;
        outline: none;
        text-indent: .5rem;
        border: 1px solid rgba(0,0,0,0.2);
    }
    .item-wrapper-input {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }
    .image-upload {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }
    .file-upload {
        padding-top: .5rem;
        font-family: 'Inter', sans-serif;
        font-size: 15px;
    }
    input[type=file]::file-selector-button {
        padding: 10px 2vmax 10px 2vmax;
        text-align: center;
        transition: 0.5s;
        background-size: 200% auto;
        cursor: pointer;
        border-radius: 5px;
        color: white;
        font-weight: 800;
        transition: 0.5s;
        height: 45px;
        border: none;
        background-image: linear-gradient(to right, color.$blue-third 0%, color.$light-blue 51%, color.$blue-third 100%);
        &:hover {
            background-position: right center;
        }
    }
    .image-preview {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        padding-top: 1rem;
    }

    .select-component {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        width: 51% !important;
        align-items: center;
        .select-condition {
            width: 100% !important;
            @media (max-width: 768px) {
                width: 90%;
            }
        }
        .condition-description {
            cursor: help;

        }
    }
}



.seller-display-wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 100%;
    width: 100%;
    .reviews {
        padding-bottom: 1rem;
        //make 3x3 grid and then 2x2 and 1x1 based on screen size
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        margin:auto;
        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 500px) {
            grid-template-columns: repeat(1, 1fr);
        }
        gap: 2.5rem;

        .review-wrapper {
            width: 100%;
            margin: auto;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 5px;
            padding: 1rem;
            box-shadow: 0 0 10px rgba(0,0,0,0.2);
            @media (max-width: 768px) {
                width: 90%;
            }
        }
        .review-text {
            font-size: 15px;
            font-weight: 400;
        }
        
    }
}


.modal-chat {
    .modal-content {
        overflow: hidden !important;
        padding: 1rem;
        height: 30vh !important;
        width: 30vw;
        @media (max-width: 768px) {
            width: 90vw;
            .title {
                font-size: 20px;
                width: 80%;
                padding-bottom: 1rem;
            }
        }
        #product-select {
            width: 100% !important;

        }
    }
    .bottom-modal {
        //bottom right of modal
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        gap: 1rem;

    }
}

.modal {
    overflow: hidden !important;
    .modal-content {
        padding: 1rem;
        height: 50vh !important;
        width: 30vw;
        overflow: hidden !important;
        .modal-prompts {
            width: 100% !important;
            display: flex;
            flex-direction: column;
            gap: 1rem !important;
            .input-rating {
                width: 80% !important;
            }
        }
    }
    .bottom-modal-actions {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding-top: 1rem;
        float: right;
        width: 45%;
        .submit-rating {
            width: 100px;
            height: 40px;
            border-radius: 5px;
            border: none;
            outline: none;
            background-color: color.$blue-third;
            color: white;
            font-weight: 700;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
                background-color: color.$blue-second;
            }
        }
    }
}


.reviewer-details {
    display: flex;
    align-items: center;
    gap: 1rem;
    .name {
      margin-right: 8px;
      h2 {
        font-size: 20px;
        font-weight: 500;
      }
    }
  
    .profile-image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
  

.loader-seller {
    position: absolute !important;
    height: 100vh !important;
}

.seller-products {
    width: 80%;
    margin: auto;
}

.product-item {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}
.edit-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    &:hover {
        color: color.$blue-second;
    }
}
.products-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .product-wrapper {
        width: 100%;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px;
        padding: 1rem;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
        transition: ease-in-out .3s;
        cursor: pointer;
        padding-bottom: 1rem;
        @media screen and (max-width: 600px) {
            padding-bottom: 1rem;   
            gap: 0rem;
        }
        &:hover {
            box-shadow: 0 0 10px rgba(0,0,0,0.4);
        }
        .product-description {
            @media (max-width: 768px) {
                width: 100%;
            }
        }
        .product-image {
            width: 10rem;
            height: 10rem;
            @media (max-width: 768px) {
                width: 100%;
                height: 100%;
            }
            .product-image-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}