#photos {
    /* Prevent vertical gaps */
    line-height: 0;
    
    -webkit-column-count: 5;
    -webkit-column-gap:   0px;
    -moz-column-count:    5;
    -moz-column-gap:      0px;
    column-count:         5;
    column-gap:           0px;
    width: 100%;
    margin: auto;
    overflow: hidden;
 }
 
 #photos .image-main-gallery {
   /* Just in case there are inline attributes */
   width: 100% !important;
   height: auto !important;
   cursor: pointer;
   &:hover {
       filter: brightness(0.8);
   }
 }
 
 @media (max-width: 1200px) {
   #photos {
   -moz-column-count:    4;
   -webkit-column-count: 4;
   column-count:         4;
   }
 }
 @media (max-width: 1000px) {
   #photos {
   -moz-column-count:    3;
   -webkit-column-count: 3;
   column-count:         3;
   }
 }
 @media (max-width: 800px) {
   #photos {
   -moz-column-count:    2;
   -webkit-column-count: 2;
   column-count:         2;
   }
 }
 @media (max-width: 400px) {
   #photos {
   -moz-column-count:    1;
   -webkit-column-count: 1;
   column-count:         1;
   }
 }
 

 .image-container {
   position: relative;
   .image-details {
      position: absolute;
      bottom: 15px;
      text-align: left;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      .user {
        .profile-picture-user {
          height: 50px;
          width: 50px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .information-image-user {
        width: 100%;
        display: flex;
        flex-direction: column !important;
        gap: 1.2rem;
        .username {
          font-size: 14px;
          font-weight: 600;
        }
        .location {
          width: 100%;
          font-size: 10px;
          @media (max-width: 500px) {
            font-size: 8px;
          }
        }
      }
   }
 }

 .modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modal-image {
    max-width: 75vw;
    max-height: 75vh;
  }


  .close-button {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    border: 0;
    background-color: transparent;
    color: white;
    font-size: 16px;
    transition: ease-in-out .2s;
    &:hover {
      color: lightgray;
    }
  }
}


.modal-description {
  z-index: 9999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  gap: 1.5rem;
  .user-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    .user-image {
      color: white;
      .profile-picture-user {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .user-information-profile {
      color: white;
      display: flex;
      flex-direction: column;
      gap: 1.3rem;
      .username {
        font-size: 14px;
        font-weight: 600;
      }
      .date {
        font-size: 10px;
        color: lightgray;
      }
    }
  }
  .image-details {
    font-size: 14px;
    color: white;
    font-weight: 600;
  }

}
.settings {
  text-align: left;
  .header-settings {
    padding-top: 2rem;
    font-size: 14px;
    color: white;
    font-weight: 600;
  }
}
.image-settings {
  padding-top: 1.5rem;
  font-size: 12px;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 1.4rem;
}