@use '../../../_variables.scss' as color;
@use '../../../index.scss' as main;

.sites-wrapper {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    margin: auto;
    width: 90%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 600px) {
    .view-all {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        text-align: center !important;
    }
  }

.process-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    background: -webkit-linear-gradient(color.$gradient-one, color.$gradient-two);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.site-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    .site-image {
        width: 15vmax;
    }
}

@media (max-width: 600px) {
    .process-title {
        font-size: 25px;
    }
    .site-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 2rem;
    }
    .site-image {
        width: 20vmax;
    }
  }

.product-total-card {
      width: 100% !important;
      font-size: 25px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .5rem;
      padding-bottom: 1rem;
      @media (max-width: 500px) {
          font-size: 20px;
      }
        .products-total-now {
            font-size: 30px;
            font-weight: 600;
            background: -webkit-linear-gradient(color.$gradient-one, color.$gradient-two);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

        }
  }