@use '../../_variables.scss' as color;
@use '../../index.scss' as main;


.footer-wrapper {
    display: flex;
    margin: auto;
    flex-direction: column;
    height: 250px;
    background-color: #000000;
}

.footer-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    width: 90%;
    margin: auto;
}

.footer-left {
    order: 1;
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    text-align: left !important;
    width: 30%;
    .company-description {
        padding-top: 1rem;
        color: darkgray;
        font-weight: 600;
        font-size: 12px;
    }
}

.footer-center {
    order: 2;
    flex: 1;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    text-align: left;
    .links {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        gap: 1.5rem;
        .navlink {
            text-decoration: none;
            color: white;
            font-weight: 600;
            transition: ease-in-out .2s;
            &:hover {
                color: rgba(255, 255, 255, 0.7);
            }
        }
    }
}

.footer-right {
    order: 3;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    justify-content: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    .register-form {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-right: 1rem;
        .register {
            width: 100%;
        }
    }
    .information {
        color: darkgray;
        font-size: .8vmax;
        font-weight: 700;
    }
    .register-form {
        height: 40px;
    }
    .register-email {
        height: 100%;
        border-radius: 10px;
        outline: none;
        border: none;
        text-indent: .7rem;
        font-size: 15px;
        width: 150px;
        background-color: #F6F6F6;
        &:focus {
            outline: none;
        }
    }
    .register {
        cursor: pointer;
        height: 100%;
    }
}


.footer-copyright {
    position: relative;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    border-top: solid white 2px;
    color: white;
    font-size: 15px;
    font-weight: 700;
}


@media only screen and (max-width: 768px) {
    .footer-left {
      width: 100%;
      text-align: center;
      order: 2;
    }
    .footer-center {
      width: 100%;
      text-align: center;
      order: 3;
    }
    .footer-right {
      display: none;
    }
  }
  
  @media only screen and (min-width: 769px) and (max-width: 992px) {
    .footer-right {
        display: none;
    }
  }
  
  @media only screen and (min-width: 993px) {
    .footer-left {
      width: 30%;
    }
    .footer-center {
      width: 40%;
    }
    .footer-right {
      width: 30%;
    }
  }
  @media only screen and (max-width: 768px) {
    .company-description {
      font-size: 14px;
    }
    .navlink {
      font-size: 14px;
    }
    .information {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 460px) {
    .footer-left {
      width: 40%;
      text-align: left;
      order: 1;
      margin-left: -20%;
    }
    .footer-center {
      font-size: 8px;
      width: 20%;
      text-align: center;
      order: 2;
      gap: 2rem;
      .navlink {
          font-size: 12px;
      }
    }
    .footer-right {
      display: none;
    }
  }