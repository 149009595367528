.conversations-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 100%;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: .2rem;
        width: 70vw;
        margin: auto;
    }
}

.conversations {
    width: 80%;
    margin: auto;
    padding-bottom: 3rem;
    h2 {
      font-size: 24px;
      margin-bottom: 16px;
    }
    
    .item-details {
      width: 100%;
        .item-image {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 5px;
            .item-image-image {
                width: 100%;
                height: 3rem;
                object-fit: cover;
            }
        }
    }
    .conversation-list {
      list-style: none;
      padding: 0;
      border-radius: 5px;
        cursor: pointer;
      .conversation-item {
        display: flex;
        padding: 12px;
        border-radius: 8px;
        flex-direction: column;
        justify-content: left;
        text-align: left;
        transition: ease-in-out 0.2s;
        margin-bottom: 1rem;
        width: 100% !important;
        &.read {
          background-color: #f0f0f0;
        }
  
        &.unread {
          background-color: #e6f7ff;
        }
        &:hover {
            background-color: #e6f7ff;
          }
        .profile-arranged {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2rem;
        }
        .profile-top {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
        }
        .profile-image {
          width:60px;
          height: 60px;
          overflow: hidden;
          border-radius: 50%;
  
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        
  
        .details {
          margin-left: 16px;
  
          h3 {
            font-size: 16px;
            margin-bottom: 4px;
          }
  
          p {
            font-size: 14px;
            color: #888888;
          }
        }
      }
    }
  }
  