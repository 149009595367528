@use '../../_variables.scss' as color;
@use '../../index.scss' as main;

.login-text-input {
    font-family: 'Inter', sans-serif;
    outline: none;
    height: 40px;
    text-indent: .5rem;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.2);
}
.login-password-input {
    font-family: 'Inter', sans-serif;
    outline: none;
    height: 40px;
    text-indent: .5rem;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    width: 80%;
}

@media (max-width: 500px) {
    .login-password-input, .login-text-input {
        font-size: 15px !important;
    }
}

.register-form {
    @media (max-width: 500px) {
        width: 90% !important;
    }
}


.login-wrapper {
    margin: auto;
    .login {
        display: flex;
        padding-top: 2rem;
        padding-bottom: 2rem;
        .login-right {
            width: 20%;
            flex: 50%;
            padding: 10px;
            .login-image {
                border-radius: 15px;
                width: 600px;
                height: 100%;
                object-fit: cover;
            }
        }
        .login-left {
            flex: 50%;
            width: 30vmax;
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: .5rem;
            margin: auto;
            @media (max-width: 550px) {
                width: 50%;
                overflow-x: hidden;
                max-width: 100% !important;
            }
            .login-header {
                font-size: 25px;
                font-weight: 700;
                text-align: left;
            }
            .login-subheader {
                font-size: 15px;
                font-weight: 500;
                color: darkgray;
            }
            .login-form {
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                width: 60%;
                padding-top: 1rem;
                display: flex;
                flex-direction: column;
                gap: .5rem;
                .f-name {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                }
                .l-name {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    padding-top: .5rem;
                }
                .email {
                    padding-top: .5rem;
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                }
                .password {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    padding-top: .5rem;
                    justify-content: center;
                    .view-password {
                        position: relative;
                        .view-icon {
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                            cursor: pointer;
                            transition: ease-in-out .2s;
                            &:hover {
                                color: darkgray;
                            }
                        }
                    }

                }
            }
        }
    }
}


.sign-up {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding-top: 1rem;
    width: 100%;
    justify-content: left;
    align-items: left;

}

.sign-up > div {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    width: 100%;
    text-align: left;
    @media (max-width: 500px) {
        display: block;
        text-align: left;
        align-items: left;
        justify-content: left;
        margin: auto;
    }
}

.new-user {
    font-size: 12px;
    color: darkgray;
}

.sign-up-text {
    text-decoration: none;
    background: -webkit-linear-gradient(180deg, color.$gradient-one, color.$gradient-two);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 12px;
}

.google-authentication {
    .google-auth {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
    }
}


.error-text {
    padding-top: .5rem;
    color: darkred;
    font-size: 12px;
}

.error-text::first-letter {
    text-transform: capitalize;
}

.file {
    padding-bottom: 1rem;
    .image-preview {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 1rem;
    }
    .preview-image {
        border: 1px solid rgba(0,0,0,0.2);
        padding: 1rem;
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 50%;
    }
    .file-upload {
        padding-top: .5rem;
        font-family: 'Inter', sans-serif;
        font-size: 15px;
    }
    input[type=file]::file-selector-button {
        padding: 10px 2vmax 10px 2vmax;
        text-align: center;
        transition: 0.5s;
        background-size: 200% auto;
        cursor: pointer;
        border-radius: 5px;
        color: white;
        font-weight: 800;
        transition: 0.5s;
        height: 45px;
        border: none;
        background-image: linear-gradient(to right, color.$blue-third 0%, color.$light-blue 51%, color.$blue-third 100%);
        &:hover {
            background-position: right center;
        }
    }
}

.validating {
    height: 20px !important;
    width: 20px !important;
}

.reset-password {
    margin: auto;
    display: flex;
}

.success-text {
    font-size: 15px;
    padding-top: .5rem;
}

.verification-code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
    .code-wrapper {
        padding-top: .5rem;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        .code-input {
            height: 50px;
            width: 40px;
            font-size: 20px;
            outline: none;
            text-align: center;
        }
    }
}

@media screen and (max-width: 1000px) {
    .login-wrapper {
        .login {
            .login-left {
                width: 100%;
                .login-form {
                    width: 90%;
                }
            }
            .login-right {
                width: 50%;
                .login-image {
                    width: 400px !important;
                }
            }
        }
    }
    
}

@media screen and (max-width: 780px) {
    .login-wrapper {
        .login {
            .login-left {
                width: 90%;
                .login-form {
                    width: 90%;
                }
            }
            .login-right {
                width: 50%;
                .login-image {
                    width: 250px !important;
                }
            }
        }
    }
    
}

@media screen and (max-width: 560px) {
    .login-wrapper {
        .login {
            .login-left {
                width: 80%;
                margin: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .login-form {
                    width: 100%;
                    // all children div gap 
                    .email {
                        gap: 1rem;
                        padding-bottom: .5rem;
                    }
                    .password {
                        gap: 1rem;
                    }
                }
                .login-header, .login-subheader {
                    width: 100%;
                    text-align: left;
                    margin: auto;
                }
            }
            .login-right {
                display: none;
            }
        }
    }
    
}