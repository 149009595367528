.image-preview-image {
    .preview-image-image {
        width: 80%;
        max-height: 600px;
        object-fit: cover;
        border-radius: 10px;
    }
}

.image-wrapper-internal {
    width: 80%;
    margin: auto;
    .image-internal {
        margin: auto;
        width: 80%;
    }
}