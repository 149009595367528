@use '../../../_variables.scss' as color;

.search-contents button, .search-contents .search-input-wrapper, .search-contents .search-input {
    height: 50px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin:0;
  }

.search-contents {
    width: 30vmax;
}
.button {
    flex: 1 1 auto;
    margin: 10px;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.search-contents .search-submit {
    cursor: pointer;
    font-weight: 700;
    font-size: 15px;
    float: right;
    margin-left: -1px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5vmax;
    transition: 0.5s;
    background-image: linear-gradient(to right, color.$gradient-one 0%, color.$gradient-two 51%, color.$gradient-one 100%);
    border: 1px solid black;
    border-left: none;
    &:hover {
        background-position: right center;
    }
}
@keyframes rainbow { 
    0%{background-position:left}
    50%{background-position:right}
    100%{background-position:left}
  }
.search-contents .search-input-wrapper {
    overflow: hidden;
    border: none;
    outline: none;
}
.search-contents .search-input {
    border:0;
    width: 30vmax;
    padding: 0 3px;
    text-indent: .7rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 15px;
    font-family: 'Inter', sans-serif;
    border: 1px solid black;
    border-right: none;
    &:focus {
        outline: none;
    }
}