.payment-wrapper {
    padding-top: 1rem;
    padding-bottom: 5rem;
    width: 80%;
    margin: auto;
    .main-header-payment {
        margin: auto;
        text-align: center;
    }
}

.success-icon {
    font-size: 50px !important;
}

.purchase-details {
    //rounded card with lighr gray background
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 50vw;
    margin: auto;
    .item-amount, .item-type {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        margin: auto;
        gap: .8rem;
        text-align: center;
        justify-content: center;
    }
    .total {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        justify-content: center;
        padding-bottom: .5rem;
    }
}