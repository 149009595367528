@use '../../_variables.scss' as color;

.subscription-wrapper {
  padding-top: 1rem;
  padding-bottom: 5rem;
  width: 80%;
  margin: auto;
}

.subscription-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items at the top */
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.subscription-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tier-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: center;
  padding-bottom: .5rem;
}

.tier-features {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
  float: left;
  margin-left: 0%;
  justify-content: left;
}

.gold {
  /*gradient text*/
  background: -webkit-linear-gradient(color.$gradient-one, color.$gradient-two) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.platinum {
  /*gradient text*/
  background: -webkit-linear-gradient(color.$light-blue, color.$blue-darkest) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.tier-description {
  font-size: 14px;
  color: #555555;
}

.tier-features li {
  list-style: none;
}

.tier-features li:before {
  content: '✓';
  margin-right: 8px;
  color: color.$blue-darkest;
}

.price-big {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  
  .price {
    font-size: 32px;
    font-weight: bold;
    color: color.$blue-darkest;
  }
  
  .per-month {
    font-size: 14px;
    color: #555555;
  }
}

.subscribe-button {
  width: 100%;
  margin-top: auto; /* Push the button to the bottom */
  font-size: 25px !important;
  position: relative;

  .subscribe-button-btn {
    width: 100% !important;
  }
}


.subtitle {
    color: darkgray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 80%;
    gap: 1rem;
    padding-bottom: 1rem;
}