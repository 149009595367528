@use '../../../_variables.scss' as color;
@use '../../../index.scss' as main;

.process-wrapper {
    position: relative;
    margin-bottom: -1rem;
}

.headers {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto;
}

.section-header {
    flex: 1 0 35%;
}

.section-subheader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1vmax;
    color: darkgray;
    flex: 1 0 35%;
    font-weight: 600;
}

.item-process {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
    background-color: color.$blue-darkest;
    .process-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 35px;
        font-weight: 700;
        background: -webkit-linear-gradient(color.$gradient-one, color.$gradient-two);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media (max-width: 500px) {
            width: 75% !important;
            text-align: center;
            font-size: 40px !important;
        }
        @media (max-width: 400px) {
            width: 70% !important;
            text-align: center !important;
            font-size: 30px !important;
        }
    }
    .section-icon {
        padding-bottom: 1rem;
        font-size: 50px !important;
        color: white !important;
        
    }
    .process-description {
        color: white;
        font-size: 20px;
        width: 70%;
        padding-top: 2rem;
    }
}