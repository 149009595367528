@use '../../_variables.scss' as color;


.chat-wrapper {
    padding-top: 1rem;
    width: 80%;
    margin: auto;
    padding-bottom: 2rem;
}
.quote-action {
  @media (max-width: 768px) {
    width: 100% !important;
    gap: .5rem;

  }
}
.quote-body {
  display: flex;
  align-items: center;
  gap: .4rem;
  @media (max-width: 768px) {
    width: 100% !important;
    gap: .5rem;

  }
}

.reminder {
  font-size: 10px;
  color: #777;
}

.complete-quote {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  font-size: 15px;
}

.quote-message-container {
  background-color: white;
  border: 1px solid rgba(0,0,0,0.2);
  color: black;
  padding: 0.75rem;
  border-radius: 1rem;
  word-wrap: break-word;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 0.5rem;
  width: 30vw;
  @media (max-width: 500px) {
    width: 50vw;
  }
}
.seller-profile {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding-bottom: 2rem;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
        width: 80%;
        gap: .5rem;
    }
    .seller-profile-main {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        @media (max-width: 768px) {
            width: 80%;
            gap: .5rem;
            justify-content: left;
            align-items: left;
            margin: auto;
        }
        .seller-profile-image {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            overflow: hidden;
            margin: auto;
            .profile-picture {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .seller-name {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .profile-link {
                font-size: 1.5rem;
                font-weight: 600;
                color: color.$blue-darkest;
                text-decoration: none;
                font-size: 15px;
                &:hover {
                    text-decoration: underline;
                }
                margin-top: -.5rem;
            }
        }
    }
    .item-details {
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 10px;
        padding: 1rem;
        margin-top: 1.5rem;
        width: 30%;
        cursor: pointer;
        transition: ease-in-out 0.2s;
        @media (max-width: 500px) {
            width: 80%;
            border: none;
            margin: auto;
            &:hover {
              background-color: transparent !important;
              border: none !important;
          }
        }
        &:hover {
            background-color:rgba(0, 0, 0, 0.1);
            border: 1px solid rgba(0,0,0,0.5);
        }
        //put item name and item image next to each other horizontally with gap
            display: flex;
            flex-direction: row;
            gap: 1rem;
            @media (max-width: 768px) {
                flex-direction: row;
                width: 90%;
            }
            .item-image {
                width: 100%;
                height: 7rem;
                margin: auto;
                border-radius: 10px;
                overflow: hidden;
                .item-picture {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .item-name {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 70%;
                @media (max-width: 768px) {
                    width: 100%;
                }
                .price {
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: color.$blue-darkest;
                    text-decoration: none;
                    font-size: 15px;
                    margin-top: -.5rem;
                }
            }
    }
}


.chat-form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto !important;
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
  
  .chat-input {
    flex-grow: 1;
    height: 2.5rem;
    font-size: 15px;
    text-indent: .5rem;
    outline: none;
    border: none;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .submit-chat {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 50%;
    padding: 2rem;
    transition: ease-in-out 0.2s;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  


  .right {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  
    .message {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  
    .message-container {
      background-color: #3f51b5;
      color: #fff;
      padding: 0.75rem;
      border-radius: 1rem;
      word-wrap: break-word;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      margin-bottom: 0.5rem;
      max-width: 80%;
    }
  
    .message-date {
      color: #ccc;
      font-size: 0.8rem;
      font-weight: 600;
    }
  }
  
  .left {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
  
    .message {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .message-container {
      background-color: #e0e0e0;
      color: #333;
      padding: 0.75rem;
      border-radius: 1rem;
      word-wrap: break-word;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 0.5rem;
      max-width: 80%;
    }
  
    .message-date {
      color: #777;
      font-size: 0.8rem;
      font-weight: 600;
    }
  }
  

  .go-back {
    padding-bottom: 2rem;
    .go-back-link {
        font-size: 1.5rem;
        font-weight: 600;
        color: color.$blue-darkest;
        text-decoration: none;
        font-size: 15px;
        text-decoration: none;
        
        &:hover {
            text-decoration: underline;
        }
        margin-top: -.5rem;
    }
  }

  .message-bullet {
   padding-left: .2rem;
    padding-right: .2rem;
    color: #777;
      font-size: 0.8rem;
      font-weight: 600;
  }

  .seller-actions {
    .update-status {
      display: flex;
      flex-direction: row;
      gap: .5rem;
      width: 100%;
      //switch to 2x2 grid on mobile 
      @media (max-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
      }
      .action-button {
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
  .review-button {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
  }
  .buyer-notice {
    margin-top: -1rem;
    #link-buyer {
      font-size: 1.5rem;
      font-weight: 600;
      color: color.$blue-darkest;
      text-decoration: none;
      font-size: 15px;
      &:hover {
          text-decoration: underline;
      }
      margin-top: -.5rem;
    }
  }