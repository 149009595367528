.contact-wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.contact-page {
    display: flex;
    .image-section {
      width: 50%;
      .beach-image {
        width: 80%;
        height: 100%;
        margin-left: 2rem;
        object-fit: cover;
        border-radius: 15px;
      }
    }
    .form-section {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .interior-form {
          width: 50%;
          margin: auto;
        .input-field {
            padding-bottom: 1rem;
            display: flex;
            flex-direction: column;
            gap: .5rem;
            .textarea {
                height: 100px;
                width: auto;
            }
        }
        .submit-button-interior {
            width: 100% !important;
        }
      }
    }
}

@media (max-width: 900px) {
    .beach-image {
        display: none;
    }
    .contact-page {
        display: block;
        width: 100%;
        .form-section {
            width: 80vh;
        }
    }

}

@media (max-width: 320px) {
    .beach-image {
        display: none;
    }
    .contact-page {
        display: inherit;
        .form-section {
            width: 100%;
            .interior-form {
                width: 100%;
            }
        }
    }

}

.success-text {
    padding-bottom: 1rem;
    font-weight: 600;
}