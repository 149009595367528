.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    @media (max-width: 768px) {
      width: 80%;
    }
  }
  
  .modal-container h2 {
    margin-bottom: 10px;
  }
  
  .modal-container .MuiTextField-root {
    margin-bottom: 10px;
  }
  
  .modal-container .MuiButton-root {
    margin-top: 10px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }