@use '../../_variables.scss' as color;
@use '../../index.scss' as main;

.navbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*background-color: rgba(0, 0, 0, 0);*/
    background-color: black;
    z-index: 999;
    position: sticky;
    padding-top: 1rem;
    padding-bottom: .5rem;
}
  
.navbar-left {
    order: 1;
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    
}

@keyframes rainbow { 
    0%{background-position:left}
    50%{background-position:right}
    100%{background-position:left}
  }
.navbar-centered {
    order: 2;
    flex: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: auto;
    .links {
        display: flex;
        gap: 1rem;
        justify-content: center;
    }
    .navlink {
        text-decoration: none;
        color: white;
        font-weight: 700;
        transition: ease-in-out .2s;
        font-size: 20px;
        
        &:hover {
            color: color.$gradient-one;
        }
    }
    .dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        z-index: 999;
    }
    .dropdown-toggle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: white !important;
        font-weight: 600;
        transition: ease-in-out .2s;
        font-size: 20px;
        cursor: pointer;
        &:hover {
            color: color.$gradient-one !important;
        }
    }
    
    .active {
        text-decoration: none;
        background-image: linear-gradient(transparent, transparent),linear-gradient(transparent, transparent),linear-gradient(to right, color.$gradient-one, color.$gradient-two);
            background-repeat: no-repeat;
            background-position: 120%, 140%, 0 145%;

        background-size: 100% 10px;
    }
}
.dropdown-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 9999;
    font-size: 20px;
    text-decoration: none !important;
    position: absolute;
    top: 100%;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 18px;
    color: #212529;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    font-family: 'Inter', sans-serif;
}
.dropdown-item {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: black;
    padding: 10px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.15);
    }
}
.active {
    color: color.$gradient-one;
}

.dropdown-arrow {
    margin-left: -.2rem;
}
  
  .btn[data-toggle="dropdown"] {
    &:focus {
      outline: 0;
    }
    &:active {
      box-shadow: none;
    }
    &:checked + .dropdown-menu {
      display: block;
    }
  }

.navbar-right {
    order: 3;
    padding-right: 5rem;
    vertical-align: middle;
    justify-content: center;
    .sign-in {
        cursor: pointer;
        background-color: transparent;
        border-radius: 20px;
        border: none;
        color: white;
        font-weight: 800;
        text-transform: uppercase;
        transition: 0.5s;
        background-image: linear-gradient(to right, color.$gradient-one 0%, color.$gradient-two 51%, color.$gradient-one 100%);
        &:hover {
            background-position: right center;
        }
    }
}

.signed-in {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .icons {
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        align-items: center !important;
        flex-direction: row;
        gap: 1rem;
        color: white !important;
        transition: ease-in-out .2s;
        .heart-icon {
            font-size: 35px !important;
        }
        .account-icon {
            display: flex;
            align-items: center;
            color: white!important;
            font-size: 40px !important;
            padding-right: .5rem;
            transition: ease-in-out .2s;
        }
        .active > svg {
            color: color.$gradient-one !important;
        }
        .active-icon {
            color: color.$gradient-one !important;
        }
        .heart-icon:hover {
            color: red;
        }
        .account-icon:hover {
            color: color.$gradient-one !important;
        }
    }
}

.hamburger {
    display: none;
    .hamburger-icon {
        color: white !important;
    }
}

.logo-left {
    display: none;
}

@media (max-width: 785px) {
    /* styles for screen widths up to 480px */
    .navbar-wrapper {
      flex-direction: column;
      gap: 1rem;
      height: 40px;
    }
    .logo-left {
        display: flex;
        justify-content: right;
        align-items: right;
        position: absolute;
        left: 20px;
        cursor: pointer !important;
        .logo-left-image {
            width: 40px;
            height: 40px;
        }
    }
    .dropdown-menu {
        z-index: 9999;
    }
    .active-menu {
        height: auto;
    }
    .hamburger {
        display: flex;
        justify-content: right;
        align-items: right;
        position: absolute;
        right: 20px;
        .hamburger-icon {
            font-size: 35px !important;
            cursor: pointer;
            &:hover {
                background-color: rgba(0, 0, 0, 0.15) !important;
            }
        }
    }
    .navbar-left, .navbar-centered, .navbar-right {
      width: 100%;
      padding-left: 0rem;
      text-align: center;
    }
    .navbar-left {
        display: flex;
        margin: auto;
      order: 1;
    }
    .navbar-right {
      order: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
    .navbar-centered {
      order: 2;
      z-index: 999;
    }
  }
  @media (max-width: 410px) {
      .navbar-centered {
        .navlink, .dropdown-toggle, .dropdown-item {
            margin: auto;
            font-size: 2vmax !important;
        }
      }
  }

  @media (max-width: 380px) {
    /* styles for screen widths up to 480px */
    .navbar-wrapper {
      flex-direction: column;
      gap: 1rem;
      height: 40px;
    }
    .logo-left {
        display: flex;
        justify-content: right;
        align-items: right;
        position: absolute;
        left: 20px;
        cursor: pointer !important;
        .logo-left-image {
            width: 40px;
            height: 40px;
        }
    }
    .active-menu {
        height: auto;
        z-index: 999;
    }
    .hamburger {
        display: flex;
        justify-content: right;
        align-items: right;
        position: absolute;
        right: 20px;
        .hamburger-icon {
            font-size: 35px !important;
            cursor: pointer;
            &:hover {
                background-color: rgba(0, 0, 0, 0.15) !important;
            }
        }
    }
    .navbar-left, .navbar-centered, .navbar-right {
      width: 100%;
      padding-left: 0rem;
      text-align: center;
    }
    .navbar-left {
        display: flex;
        margin: auto;
      order: 1;
    }
    .navlink, .dropdown-toggle, .dropdown-item {
        margin: auto;
        font-size: 14px !important;
    }
    .active {
        text-decoration: none;
        background-image: linear-gradient(transparent, transparent),linear-gradient(transparent, transparent),linear-gradient(to right, color.$gradient-one, color.$gradient-two);
            background-repeat: no-repeat;
            background-position: 120%, 140%, 0 145%;

        background-size: 100% 0px !important;
        color: color.$gradient-one !important;
    }
    .navbar-right {
      order: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
    .navbar-centered {
      order: 2;
    }
  }

  .notification {
    transition: ease-in-out .2s;
    &:hover {
        color: color.$gradient-one !important;
    }
  }
  .account-icon {
    position: relative;
  }
  .unread-count {
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    color: white;
    font-size: 12px;
    font-weight: bold;
  }