@use '../../_variables.scss' as color;
@use '../../index.scss' as main;

.seller-signup-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 60%;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    input {
        font-size: 14px;
    }
    .form-content {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
    .seller-input {
        width: 50%;
        @media (max-width: 768px) {
            width: 90%;
        }
        border-radius: 5px;
        height: 40px;
        outline: none;
        text-indent: .5rem;
        border: 1px solid rgba(0,0,0,0.2);
    }
    .item-wrapper-input {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }
    .image-upload {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }
    .file-upload {
        padding-top: .5rem;
        font-family: 'Inter', sans-serif;
        font-size: 15px;
    }
    input[type=file]::file-selector-button {
        padding: 10px 2vmax 10px 2vmax;
        text-align: center;
        transition: 0.5s;
        background-size: 200% auto;
        cursor: pointer;
        border-radius: 5px;
        color: white;
        font-weight: 800;
        transition: 0.5s;
        height: 45px;
        border: none;
        background-image: linear-gradient(to right, color.$blue-third 0%, color.$light-blue 51%, color.$blue-third 100%);
        &:hover {
            background-position: right center;
        }
    }
    .image-preview {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        padding-top: 1rem;
    }
    .form-contents {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
}
