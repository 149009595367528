@use '../../_variables.scss' as color;
@use '../../index.scss' as main;


.products-wrapper-main {
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 90%;
  
    @media (max-width: 768px) {
      width: 100%;
      overflow-x: hidden;
    }
  }
  
  .search-wrapper-products {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    padding-right: 2rem;
    padding-top: .5rem;
    height: auto;
  
    @media (max-width: 768px) {
      padding-top: 1rem;
      flex-direction: column;
      margin: 0;
      gap: .5rem;
      height: auto;
      justify-content: left;
      align-items: left;
      width: 80%;
      .filter-items, .filter-price {
        align-items: left;
        margin: auto;
      }
    }
    @media (max-width: 450px) {
        padding-top: 1rem;
        padding-left: .5rem;
        flex-direction: column;
        margin: 0;
        gap: .5rem;
        height: auto;
        justify-content: left;
        align-items: left;
        width: 80%;
        .filter-items, .filter-price {
          align-items: left;
          margin: auto;
        }
      }
  
    .search {
      display: flex;
      justify-content: left;
      align-items: center;
      @media (max-width: 768px) {
        padding-bottom: 1rem;
        justify-content: left;
        width: 95%;
      }
      
    }

    .filter-items {
      display: flex;
      justify-content: center;
      align-items: left;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 1rem;
      @media (max-width: 450px) {
        justify-content: left;
      }
    }
    .store {
      padding-left: 1rem;
      @media (max-width: 768px) {
        justify-content: left;
        padding-left: 0rem;
      }
    }
    .filter-price {
      width:20%;
      display: flex;
      align-items: center !important;
      justify-content: center;
      margin-top: auto !important;  
        margin-bottom: auto !important;
        padding-left: 2rem;
      @media (max-width: 768px) {
              width: 90%;
              align-items: left;
              justify-content: left;
              padding-left: 0rem;
            }
      @media (max-width: 450px) {
          width: 90%;
          align-items: left;
          justify-content: left;
          padding-left: 0rem;
      }
   
}
}

.products-header {
    padding-top: 1rem;
    font-size: 35px;
    @media (max-width: 768px) {
        text-align: left;
        padding-left: 1rem;
      }
    .products {
        font-weight: 800;
    }
}

.categories {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    border: none !important;
    outline: none;
    width: 90%;
    margin: auto;
    .category-card {
        position: relative;
        cursor: pointer;
        border-radius: 10px;
        transition: ease-in-out .2s;
        .category-name {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            font-size: 30px;
            color: white;
            font-weight: 800;
        }
        .category-image {
            width: 410px;
            height: 300px;
            object-fit: cover;
            border-radius: inherit;
            filter: grayscale(.5) brightness(.7);
        }
        &:hover .category-image {
            filter: grayscale(0) brightness(.8);
        }
    }
}

@media (max-width: 1200px) {
    .categories {
      flex-wrap: wrap;
    }
    .category-card {
      width: 45%;
    }
  }
  
  @media (max-width: 768px) {
    .categories {
      flex-wrap: wrap;
    }
    .category-card {
      width: 80%;
    }
  }
  
  @media (max-width: 480px) {
    .categories {
      flex-wrap: wrap;
      margin: auto;
      padding: 1rem;
    }
    .category-card {
      width: 95%;
    }
  }
  
.products-all {
    padding-top: 2rem;
    padding-bottom: 1rem;

}

.page-navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .previous-page {
  display: flex;
  justify-content: flex-start;
  }
  .load-more {
    display: flex;
    justify-content: flex-end;
  }
  }




.reset-filters {
  position: fixed;
  bottom: 20px;
  right: 30px;
}

.product-count {
  padding-top: 1rem;
  font-size: 20px;
  .products-total {
    font-weight: 600;
  }
  @media (max-width: 600px) {
    padding-left: 1rem;
  }
}

.search {
  display: flex;
  align-items: center !important;
  .filter-on {
    margin-top: auto;
    margin-bottom: auto;
    vertical-align: center;
    .toggle-filter {
      padding-left: 1rem;
      .filter-icon {
        cursor: pointer;
        font-size: 35px !important;
        transition: ease-in-out .2s;
        &:hover {
          color: darkgray !important;
        }
      }
    }
  }
}

