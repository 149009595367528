@use '../../../_variables.scss' as color;

.item-card-welcome-wrapper {
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
    margin: auto;
}

.cards {
    display: flex;
    gap: 3rem;
    flex-direction: row;
    margin: auto;
}

.register-link {
    color: black;
    text-decoration: underline;
}

.section-header {
    font-weight: 600;
    padding-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.view-button {
    display: flex;
    align-items: center;
}
.header {
    flex: 1 0 35%;
    font-size: 35px;
}
.explore-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1vmax;
    color: darkgray;
    flex: 1 0 35%;
    @media (max-width: 500px) {
        font-size: 10px;
    }
}
.card {
    width: 300px;
    cursor: pointer;
    transition: ease-in-out .2s;
    padding: 20px 30px 20px 30px;
    border-radius: 10px;
    &:hover {
        background-color: rgba(0,0,0,.06);
    }
    .card-image {
        height: 340px;
        width: 300px;
        border-radius: 10px;
        object-fit: cover;
    }
    .card-header {
        padding-top: 1rem;
        font-size: 25px;
        font-weight: 700;
        background: -webkit-linear-gradient(color.$gradient-one, color.$gradient-two);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .card-text {
        padding-top: 1rem;
        font-size: 15px;
    }
}


@media (max-width: 1000px) {
    .cards {
        display: flex;
        gap: 3rem;
        flex-direction: column;
        margin: auto;
    }
    .header {
        flex: 1 0 35%;
        text-align: left;
        padding-left: 1rem;
        font-size: 25px;
    }
    .card {
        width: 400px;
        .card-image {
            height: 280px;
            width: 400px;
            border-radius: 10px;
            object-fit: cover;
        }
    }
  }

@media (max-width: 600px) {
    .cards {
        display: flex;
        gap: 3rem;
        flex-direction: column;
        margin: auto;
    }
    .header {
        flex: 1 0 35%;
        text-align: left;
        padding-left: 1rem;
        font-size: 25px;
        text-align: center;
    }
    .card {
        width: 350px;
        .card-image {
            height: 180px;
            width: 100%;
            border-radius: 10px;
            object-fit: cover;
        }
    }
  }

  @media (max-width: 400px) {
    .cards {
        display: flex;
        gap: 3rem;
        flex-direction: column;
        margin: auto;
    }
    .header {
        flex: 1 0 35%;
        text-align: left;
        padding-left: 1rem;
        font-size: 25px;
        text-align: center;
    }
    .card {
        width: 80%;
        .card-image {
            height: 180px;
            width: 100%;
            border-radius: 10px;
            object-fit: cover;
        }
    }
  }