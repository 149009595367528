@use '../../../_variables.scss' as color;


hr {
    outline: none;
    border: 1px solid rgba(0,0,0,.06);
}

.article-wrapper-post {
    display: flex !important;
    flex-direction: column !important;
    padding-bottom: .5rem;
    display: flex;
    flex-direction: row;
    flex-grow: inherit;

}
.article {
    display: flex;
    height: auto;
    flex-direction: column;
    transition: ease-in-out .2s;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 10px 10px;
    margin-bottom: 1rem;

    &:hover {
        background-color: rgba(0,0,0,.06);
    }
}
.article-wrapper {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
}

.article-contents {
    flex: 50%;
    padding-top: 1rem;
}
.article-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .side-image {
        width: 100%;
        height: 220px;
        border-radius: 10px;
        object-fit: cover;
    }
}
.article-header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    .profile-picture {
        .profile-picture-image {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .article-information {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        .name {
            font-size: 17px;
            font-weight: 600;
        }
        .date {
            font-size: 14px;
            color: darkgray;
        }
    }
    
}
.article-title {
    padding-top: .5rem;
    font-size: 20px;
    font-weight: 600;
}

.article-description {
    font-size: 14px;
    color: darkgray;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-bottom: .5rem;
    width: 80%;
}