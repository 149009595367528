@use '../../_variables.scss' as color;


.terms {
    padding-top: 1rem;
    padding-bottom: 2rem;
    width: 90%;
    margin: auto;
    .terms-header {
        font-size: 30px;
        font-weight: 600;
        padding-bottom: 1rem;
    }
    .terms-content {
        font-size: 15px;
    }
    .bold {
        font-weight: 600;
    }
    .email {
        color: color.$gradient-one;
    }
}