.external-link {
    position: absolute;
    top: 40px;
    right: 50px;
    padding: 2px 5px 2px 5px;
    background-color: white;
    transition: ease-in-out .2s;
    
    .external-icon-active {
        color: red;
    }
    &:hover {
        color: red;
    }
}

#heart-icon {
    font-size: 25px !important;
}