@use '../../_variables.scss' as color;


.loader-wrapper {
    width: 100%;
    height: 100vh;
    background-color: white;
    z-index: 999;
}

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}