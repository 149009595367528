@use '../../../_variables.scss' as color;

.right-content > div{
    padding-bottom: 2rem;
}

.newsletter-signup {
    width: 100%;
    .form-contents {
        display: flex;
        flex-direction: row;
        gap: .5rem;
    }
}


.section-header-right {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: .7rem;
}

.content-wrapper {
    display: flex;
    flex-grow: inherit;
    border-radius: 5px;
    align-items: center;
    gap: 1rem;
    width: 320px;
    cursor: pointer;
    background-color: rgba(0,0,0,0.02);
    transition: ease-in-out .2s;
    margin-bottom: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    &:hover {
        background-color: rgba(0,0,0,0.10);
    }
    .content-image {
        flex: 30%;
        border-radius: inherit;
        .left-image {
            object-fit: cover;
            width: 120px;
            height: 150px;
            border-radius: inherit;
        }
    }
    .content-text {
        width: 100%;
        .post-author {
            font-size: 15px;
            color: darkgray;
            padding-bottom: 1rem;
        }
        .post-title {
            font-size: 20px;
            font-weight: 600;
        }
    }
}

.thanks {
    font-size: 15px;
    background: -webkit-linear-gradient(color.$gradient-one, color.$gradient-two);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}