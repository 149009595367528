@use '../../_variables.scss' as color;
@use '../../index.scss' as main;


.edit-wrapper-post {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.form-header {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 1rem;
}

.submit-button {
    width: 100%;
    margin-top: 1rem;
}

.write-new-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .file {
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        .preview-image {
            width: 100%;
            height: 150px;
            object-fit: cover;
            border-radius: 15px;
        }
        .file-upload {
            padding-top: .5rem;
            font-family: 'Inter', sans-serif;
            font-size: 15px;
        }
        input[type=file]::file-selector-button {
            padding: 10px 2vmax 10px 2vmax;
            text-align: center;
            transition: 0.5s;
            background-size: 200% auto;
            cursor: pointer;
            border-radius: 5px;
            color: white;
            font-weight: 800;
            transition: 0.5s;
            height: 45px;
            border: none;
            background-image: linear-gradient(to right, color.$blue-third 0%, color.$light-blue 51%, color.$blue-third 100%);
            &:hover {
                background-position: right center;
            }
        }
    }
    .title {
        padding-top: .5rem;
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }
    .content {
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        .content-area {
            padding-top: .5rem;
        }
    }
}

.validating {
    height: 20px !important;
    width: 20px !important;
}

.user-posts {
    padding-top: 2rem;
}

.articles-wrapper-internal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    .article-internal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100% !important;
    }
    hr {
        outline: none;
        border: 1px solid rgba(0,0,0,.06);
    }
}

.success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .success-message {
        margin: auto;
        text-align: center;
        padding-bottom: 2rem;
    }
}

.delete-post {
    padding-top: 1rem;
    display: flex;
    justify-content: left;
    align-items: left;
    .delete-button {
        width: 100%;
    }
}


.buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.edit-form-main {
    width: 80%;
}