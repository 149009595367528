// SellerDashboard.scss
@use '../../_variables.scss' as color;
.container {
    gap: 20px;
    padding: 2rem;
  }

.seller-details {
    @media (max-width: 768px) {
        width: 80%;
        overflow: hidden;
        margin: auto;
      }
}
  
  .tiles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding-bottom: 2rem;
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
  
  .tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //gradient background color
    background: linear-gradient(
      90deg,
      color.$gradient-one 0%,
      color.$gradient-two 100%
    );
    color: white;
    border-radius: 5px;
    text-align: center;
    h3 {
        @media (max-width: 768px) {
            font-size: 1rem;
        }
    }
    .value-int {
        font-size: 2rem;
        font-weight: bold;
        @media (max-width: 768px) {
            font-size: 1.5rem;
        }
    }
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0px;
    }
  }

  .items-seller {
    // half right half left
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding-bottom: 2rem;
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
  
  .products {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    padding-bottom: 2rem;
    @media (max-width: 768px) {
        width: 100%;
        overflow: hidden;
      }
  }
  
  .product-card {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 2rem;
    background-color: #f2f2f2;
    border-radius: 5px;   
    @media (max-width: 768px) {
        width: 100%;
        overflow: hidden;
      }
  }

  .view-button-dashboard {
    //put at end
    margin-left: auto;
    border: none;
    outline: none;
    cursor: pointer;
    &:hover {
      color: color.$blue-darkest;
    }
    @media (max-width: 768px) {
        margin-left: -10px;
      }
  }

  .delete-button {
    margin-left: 0;
    border: none;
    outline: none;
    cursor: pointer;
    &:hover {
      color: color.$gradient-one;
    }
  }
  
  .product-card img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .reviews-dashboard {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 5px;
    .reviews-dashboard-seller {
        display: flex;
        flex-direction: column;
        gap: 1rem !important;
        overflow: scroll;
      }
    .all-reviews {
        padding-bottom: 1rem;
        overflow: scroll;
        max-height: 200px;
    }
  }
  

  .review-dashboard {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }