@use '../../_variables.scss' as color;
@use '../../index.scss' as main;


.header-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 2rem;
}
.header-content {
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    position: relative;
    .main-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 4rem;
        //perfectly center div in middle of image
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
    }

    .header {
        color: white;
        font-size: 35px;
        font-weight: 800;
    }
    .subheader {
        color: white;
        font-size: 25px;
        font-weight: 400;
        padding-top: 1rem;
    }
}

.items {
    padding-top: 1rem;
    padding-bottom: 2rem;
    margin: auto;
}

@media (max-width: 600px) {
    .text-header {
        margin-bottom: -2rem;
    }
    .header-content {
        .header {
            font-size: 25px;
          }
        .subheader {
            font-size: 20px;
        }
    }
    
  }

@media (max-width: 600px) {
.items {
    flex-direction: column;
}
}
  