@use '../../_variables.scss' as color;

.error-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh !important;
}

.error-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
}

@media (max-width: 700px) {
  .error-content {
    padding-bottom: 2rem;
    padding-top: 2rem;
    position: relative;
    height:0%;
    top: 0%;
    bottom: 0%;
    left: 0%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0%;
    transform: translate(0%, 0%);
  }
}

.results-header {
  font-size: 50px;
  font-weight: 800;
  text-align: left;
  background: -webkit-linear-gradient(color.$gradient-one, color.$gradient-two);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.error-header {
    font-size: 100px;
    font-weight: 800;
    text-align: left;
    background: -webkit-linear-gradient(color.$gradient-one, color.$gradient-two);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sub-text {
    text-align: left;
    font-size: 30px;
    font-weight: 600;
}

.results {
  margin: auto;
  padding-bottom: 5rem;
  @media (max-width: 700px) {
    width: 80%;
    margin: auto;
  }
}

.below-sub {
    color: darkgray;
    font-size: 20px;
    padding-top: .5rem;
    font-weight: 600;
}

.back-home {
    padding-top: 2rem;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .back-home-btn {
        width: 40%;
    }
}

@media only screen and (max-width: 600px) {
    /* Add styles for mobile devices here */
    .error-wrapper {
      height: auto; /* Change the height to auto to make it flexible */
    }
    .error-header {
      font-size: 50px; /* Decrease the font size for smaller screens */
    }
    .sub-text {
      font-size: 20px; /* Decrease the font size for smaller screens */
    }
    .below-sub {
      font-size: 16px; /* Decrease the font size for smaller screens */
    }
  }
  