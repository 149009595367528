@use '../../_variables.scss' as color;
@use '../../index.scss' as main;

.main-wrapper-product {
    display: flex;
    flex-direction: column;
}
.items-comparison {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.comparison-content {
    margin: auto;
    .nothing-to-see {
        width: 100%;
        float: left;
    }
}


.no-items {
    font-weight: 400;
    //error red color hex code
    color: #ff0000;
    text-align: left;
}
.product-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 2rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    width: 800px;
   
    width: 80%;
    .back-url {
        cursor: pointer;
    }
    .product-image {
        flex: 50%;
        justify-content: center;
        align-items: center;
        width: 100%;
        @media (max-width: 450px) {
            flex: 100%;
            align-items: left;
            justify-content: left;
            text-align: left;
            width: 100%;
        }
        .product-image-large {
            width: 90%;
            border-radius: 5px;
            object-fit: cover;
            height: 100%;
            max-height: 1300px;
        }
    }
    .product-details {
        flex: 50%;
        justify-content: center;
        align-items: center;
        @media (max-width: 450px) {
            flex: 100%;
            align-items: left;
            justify-content: left;
            text-align: left;
            width: 100%;
        }
        .original-site {
            color: color.$blue-third;
        }
        .product-title {
            font-size: 35px;
            font-weight: 600;
        }
        .seller-details {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            padding-top: 1rem;
            width: 50%;
            align-items: center;
            padding-bottom: 2rem;
            justify-content: left;
            margin-right: auto;
            @media (max-width: 768px) {
                width: 100%;
                margin: auto;
                gap: 1rem;
            }
            .seller-profile-image {
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                overflow: hidden;
                margin: auto;
                @media (max-width: 768px) {
                    margin: 0;
                }
                //center the image inside
                .profile-picture {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .seller-name {
                display: flex;
                flex-direction: column;
                justify-content: center;
                label {
                    margin-top: -1rem;
                    margin-bottom: -1rem;
                    color:  #a9a9a9;
                   
                }
                .profile-link {
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: color.$blue-darkest;
                    text-decoration: none;
                    font-size: 15px;
                    &:hover {
                        text-decoration: underline;
                    }
                    margin-top: -.5rem;
                }
            }
        }
    }
    
    .product-notes {
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        .notes-header {
            font-size: 20px;
        }
    }
    .price {
        padding-top: 1rem;
        font-size: 20px;
        font-weight: 600;
    }
    .action-buttons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .foot-buttons {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        width: 100%;
        padding-top: 1rem;
    }
    .compare-button {
        display: flex;
        align-items: center;
        margin: auto;
        text-align: center;
        justify-content: center;
        gap: .5rem;
        width: 100%;
    }
    
    .view-button {
        display: flex;
        align-items: center;
        margin: auto;
        text-align: center;
        justify-content: center;
        gap: .5rem;
    }
    
    
    .like-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .active {
        color: red !important;
    }
}

.loading-display {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pagination {
    padding-bottom: 1rem;
}

.compare {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: .7rem;
    .compare-header {
        font-size: 20px;
    }
    .count {
        font-weight: 600;
    }
    .great {
        color: darkgreen;
        font-weight: 600;
        text-transform: uppercase;
    }
    .good {
        color: lightgreen;
        font-weight: 600;
        text-transform: uppercase;
    }
    .average {
        color: yellow;
        font-weight: 600;
        text-transform: uppercase;
    }
    .poor {
        color: darkred;
        font-weight: 600;
        text-transform: uppercase;
    }
    .saved {
        font-size: 20px;
        font-weight: 600;
        background: -webkit-linear-gradient(color.$gradient-one, color.$gradient-two);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

@media (max-width: 520px) {
    .product-image {
        flex: 50%;
        margin: auto;
        .product-image-large {
          width: 100% !important;
        }
      }
}

@media (max-width: 768px) {
    .product-wrapper {
        display: flex;
        flex-direction: column;
        width: 90%;
    }
    .product-image {
      flex: 100%;
      .product-image-large {
        width: 100%;
      }
    }
    .product-details {
      flex: 100%;
    }
    .product-title {
      font-size: 28px;
    }
    .price {
      font-size: 18px;
    }
    .view-button {
      font-size: 12px;
    }
  }
  


@media (max-width: 500px) {
    .product-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        gap: 2rem;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        width: 80%;
        scroll-behavior: smooth;
    }
}



.product-status {
    padding-top: .5rem;
    padding-bottom: .5rem;
}
.status {
    border-radius: 15px;
    padding: 5px 10px 5px 10px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    width: fit-content;
    
}
.status-available {
    background-color: #006400;
    color: white;
}

.status-unavailable {
    background-color: #ff0000;
    color: white;
}

.status-pending {
    background-color: #ffa500;
    color: white;
}