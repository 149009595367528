@use '../../../_variables.scss' as color;

.product-card-wrapper {
    padding-bottom: 1rem;
    display: grid;
    gap: 1px;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    @media (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr) !important;
    }
    @media (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .product-card {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        border: 1px solid black;
        width: fit-content;
        cursor: pointer;
        transition: ease-in-out .2s;
        position: relative;
        width: 22vmax;
        @media (max-width: 1000px) {
            width: 30vmax;
        }
        @media (max-width: 714px) {
            width: 22vmax;
        }
        @media (max-width: 576px) {
            width: 350px;
            margin: auto;
            justify-content: center;
        }
        @media (max-width: 480px) {
            width: 80%;
            margin: auto;
            justify-content: center;
        }
        @media (max-width: 340px) {
            width: 25vmax;
            margin: auto;
            justify-content: center;
        }
        &:hover {
            background-color: rgba(0,0,0,.06);
        }
        .rating {
            position: absolute;
            top: 40px;
            left: 50px;
            padding: 2px 5px 2px 5px;
            font-size: 20px;
            font-weight: 600;
            font-size: 20px;
            background: white;
            color: black;
            text-transform:uppercase;
            .great {
                color: darkgreen;
                font-weight: 600;
                text-transform: uppercase;
            }
            .good {
                color: lightgreen;
                font-weight: 600;
                text-transform: uppercase;
            }
            .average {
                color: yellow;
                font-weight: 600;
                text-transform: uppercase;
            }
            .poor {
                color: darkred;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
        .saved-lower {
            position: absolute;
            bottom: 170px;
            left: 50px;
            padding: 2px 5px 2px 5px;
            font-size: 20px;
            font-weight: 600;
            font-size: 20px;
            background: white;
            color: black;
            text-transform:uppercase;
        }
        .compared-lower {
            position: absolute;
            bottom: 170px;
            right: 50px;
            padding: 2px 5px 2px 5px;
            font-size: 20px;
            font-weight: 600;
            font-size: 20px;
            background: white;
            color: black;
            text-transform:uppercase;
        }
        .product-details {
            max-width: 100%;
            text-align: left;
            padding-top: .3rem;
            .product-title {
                padding-top: .5rem;
                font-size: 22px;
                font-weight: 600;
                max-height: 80px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .condition {
                padding-top: .2rem;
                font-size: 16px;
                max-height: 70px;
                overflow: hidden;
            }
            .price {
                font-size: 18px;
                text-align: right;
                font-weight: 700;
                margin-top: .5rem;
            }
        }
    }
}

.available {
    color: #4CAF50 !important;
}

.pending {
    color: #FFC107 !important;
}

.completed {
    color: red !important;
}

.product-image-top {
    width: 100% !important;
    height: 200px !important;
    object-fit: cover;
    border-radius: 10px;
}