@use './_variables.scss' as color;

body {
    font-family: 'Inter', sans-serif;
    overflow-y: scroll;
    padding: 0;
    margin: 0;
    width: 100%;
    overflow-x: hidden;
}

.wrapper {
    margin-top: -5rem;
}

.page-background {
    left: 0;
    top: 0;
    width: 100%;
    max-height: 600px;
    object-fit: cover;
    filter: brightness(.6);
}

.centered {
    left: 0;
    position:absolute;
    text-align:center;
    bottom: 75%;
    top: 0%;
    width: 100%
}

@media (max-width: 1000px)  {
    .page-background {
        left: 0;
        top: 0;
        width: 100%;
        height: 500px;
        object-fit: cover;
        filter: brightness(.6);
    }
    .centered {
        left: 0;
        position:absolute;
        text-align:center;
        bottom: 82%;
        top: 0%;
        width: 100%
    }
}

@media (max-width: 769px)  {
    .page-background {
        left: 0;
        top: 0;
        width: 100%;
        height: 500px;
        object-fit: cover;
        filter: brightness(.6);
    }
    .centered {
        left: 0;
        position:absolute;
        text-align:center;
        bottom: 82%;
        top: 0%;
        width: 100%
    }
}

@media (max-width: 600px)  {
    .page-background {
        left: 0;
        top: 0;
        width: 100%;
        height: 500px;
        object-fit: cover;
        filter: brightness(.6);
    }
    .centered {
        left: 0;
        position:absolute;
        text-align:center;
        bottom: 82%;
        top: 0%;
        width: 100%
    }
}

@media (max-width: 450px)  {
    .page-background {
        left: 0;
        top: 0;
        width: 100%;
        height: 500px;
        object-fit: cover;
        filter: brightness(.6);
    }
    .centered {
        left: 0;
        position:absolute;
        text-align:center;
        bottom: 82%;
        top: 0%;
        width: 100%
    }
}

.page {
    width: 100%;
}

.section-header {
    font-size: 35px;
    color: black;
    font-weight: 700;

}

.gradient-bg {
    cursor: pointer;
    border-radius: 20px;
    color: white;
    font-weight: 800;
    transition: 0.5s;
    height: 45px;
    border: none;
    background-image: linear-gradient(to right, color.$gradient-one 0%, color.$gradient-two 51%, color.$gradient-one 100%);
    &:hover {
        background-position: right center;
    }
}

.gradient-bg-blue {
    cursor: pointer;
    border-radius: 20px;
    color: white;
    font-weight: 800;
    transition: 0.5s;
    height: 45px;
    border: none;
    background-image: linear-gradient(to right, color.$blue-third 0%, color.$light-blue 51%, color.$blue-third 100%);
    &:hover {
        background-position: right center;
    }
}

.product {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 5rem;
}

.btn {
    margin: 10px;
    padding: 10px 1.5vmax 10px 1.5vmax;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
}

.btn-large {
    padding: 10px 2vmax 10px 2vmax;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    @media (min-width: 1800px) {
        padding: 20px 5vmax 20px 5vmax;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.company-name {
    cursor: pointer;
    font-weight: 600;
    color: white;
    /* gradient text shit */
    background: linear-gradient(to right, color.$gradient-one,color.$gradient-two);
    background-size: 200% 200%;
    animation: rainbow 2s ease-in-out infinite;
    background-clip: text;
    -webkit-background-clip:text;
    color:rgb(255, 255, 255);
    transition: color .2s ease-in-out;
    text-shadow: none;
    display:block;
    text-align:center;
    transition: color .2s ease-in-out;
    text-transform:uppercase;
    font-weight:900;
    &:hover {
        color:rgba(0,0,0,0);
    }
}
.first {
    font-size: 18px;
}
.second {
    font-size: 30px;
}


/* Login form */

.field-type {
    font-size: 15px;
    color: black;
    @media (max-width: 500px) {
        font-size: 18px;
    }
}

.text-input {
    font-family: 'Inter', sans-serif;
    outline: none;
    height: 40px;
    text-indent: .5rem;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    @media (max-width: 500px) {
        // make bigger for smaller screens 
        width: 100%;
        font-size: 15px;

    }
}

.password-input {
    font-family: 'Inter', sans-serif;
    outline: none;
    height: 40px;
    text-indent: .5rem;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    @media (max-width: 500px) {
        // make bigger for smaller screens 
        width: 80%;
        font-size: 15px;

    }
}


.sign-submit {
    margin-top: 1rem;
    width: 100%;
}
