.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container {
    background-color: #fff;
    width: 400px;
    padding: 20px;
    border-radius: 8px;
  }
  
  .modal-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .modal-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: .2rem;
  }
  
  .form-label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .form-input {
    width: 80%;
    height: 40px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .submit-button-quote,
  .cancel-button {
    width: 120px;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button-quote {
    background-color: #007bff;
  }
  
  .cancel-button {
    background-color: #ccc;
    margin-right: 10px;
  }
  
  .submit-button:hover,
  .cancel-button:hover {
    opacity: 0.8;
  }
  