.dashboard-wrapper {
    .dashboard {
        width: 80%;
        margin: auto;
        padding-top: 2rem;
        padding-bottom: 3rem;
        .top-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }
        .welcome-user {
            font-size: 30px;
            .date-registered {
                padding-top: .5rem;
                font-size: 20px;
                @media (max-width: 550px) {
                    font-size: 14px;
                }
            } 
        }
        .profile {
            //half left half right
            display: flex;
            flex-direction: row;
            gap: 1rem;
            @media (max-width: 768px) {
                flex-direction: column;
            }
            .profile-information {
                padding-top: 1rem;
                flex: 50%;
                @media (max-width: 768px) {
                    width: 100%;
                    flex: 100%;
                }
            }
            
            .profile-details {
                flex: 50%;
                padding-top: .5rem;
                @media (max-width: 768px) {
                    width: 100%;
                    flex: 100%;
                }
            }
            .dashboard-profile-actions {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding-top: 1rem;
                @media (max-width: 768px) {
                    flex-direction: column;
                }
                .arranged-dashboard {
                    //2x2 grid
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 1rem;
                    @media (max-width: 768px) {
                        grid-template-columns: repeat(1, 1fr);
                    }
                }
                .success {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    width: 100%;
                }
                .success-icon-interior {
                    padding-left: .5rem;
                }
                .right-dashboard {
                    display: flex;
                    justify-content: top;
                    flex-direction: column;
                    gap: 1rem;
                    align-items: top;
                    @media (max-width: 768px) {
                        justify-content: center;
                    }
                }
                .left-dashboard {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 1rem;
                    @media (max-width: 768px) {
                        padding-top: 2rem;
                        width: 100% !important;
                        justify-content: center;
                        align-items: center;
                        margin: auto;
                        align-items: flex-start;
                    }
                }
                
            }
        }
        .likes {
            padding-top: 1rem;
            .likes-wrapper {
             padding-top: 1rem;
             padding-bottom: 1rem;
             display: grid;
             gap: 1rem;
             grid-template-columns: repeat(3, 1fr);
             @media (max-width: 768px) {
                 grid-template-columns: repeat(2, 1fr) !important;
             }
             @media (max-width: 576px) {
                 grid-template-columns: repeat(1, 1fr) !important;
             }
             .liked-item {
                 display: flex;
                 flex-direction: column;
                 justify-content: center;
                 align-items: center;
                 .delete-item {
                     display: flex; 
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-family: 'Inter', sans-serif;
                    .delete-text {
                        color: darkgray;
                        font-size: 14px;
                    }
                }
                .delete-icon {
                    cursor: pointer;
                    transition: ease-in-out .2s;
                    &:hover {
                        color: red;
                    }
                }
            }
           }
       }
       .profile-user-header {
        font-size: 25px;
        }
        .field-value {
            font-size: 17px;
        }
   } 
}

.profile-image {
    .profile-picture {
        border-radius: 50%;
        object-fit: cover;
        width: 100px;
        height: 100px;
    }
}

.error {
    color: darkred;
}

.dash-btn {
    height: 70px;
    width: 200px;
}


.btn-disabled {
    cursor:pointer;
    border-radius: 20px;
    color: white;
    font-weight: 800;
    transition: 0.5s;
    height: 45px;
    border: none;
    padding: 10px 2vmax 10px 2vmax;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    background-color: darkgray;
    border-radius: 10px;
    &:hover {
        background-position: right center;
        background-color: rgba(0,0,0,0.6);
    }
}

.form-field {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.changeable-input {
    border: 0;
    border-bottom: 1px solid black;
    outline: none;
    width: 70%;
}

.save-changes {
    margin-top: 1rem;
    width: 70%;
}

.update-success {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    font-weight: 600;
}
.pending-wrapper {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 100%;
    .subtitle {
        color: darkgray;
        width: 100% !important;
    }
}

.delete-likes {
    padding-top: 1rem;
}
.delete-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
}

.graph-wrapper {
    width: 80%;
    margin: auto;
    padding-bottom: 2rem;
}

.ai-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}