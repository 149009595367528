.gallery-wrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.gallery-contents {
    width: 90%;
    margin: auto;
}

.gallery-title {
    padding-top: .5rem;
    font-size: 20px;
    font-weight: 600;
}

.gallery-images {
    padding-top: 2rem;
    margin: auto;
}