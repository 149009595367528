@use '../../_variables.scss' as color;
.post {
    width: 80%;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.back-url {
    text-decoration: none;
    color: color.$blue-darkest;
    transition: ease-in-out .2s;
    &:hover {
        color: color.$light-blue;
    }
}
.post-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: inherit;
    .post-header {
        flex: 50%;
        margin-top: auto;
        margin-bottom: auto;
        .post-title {
            font-size: 35px;
            font-weight: 600;
            width: 90%;
        }
        .user-data {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
            padding-top: 1.5rem;
        }
        .name {
            color: color.$blue-darkest;
            font-size: 18px;
            font-weight: 600;
        }
        .date {
            font-size: 13px;
            color: darkgray;
        }
        .profile-picture {
            .profile-picture-image {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }
    .side-image {
        flex: 50%;
        .image {
            width: 100%;
            height: 240px;
            object-fit: cover;
            border-radius: 15px;
        }
    }
}

.post-text {
    padding-top: 1rem;
    width: 100%;
    text-align: left;
    line-height: 30px;
    font-size: 17px;
}

.no-format {
    font-family: 'Inter', sans-serif;
    white-space: pre-line;
}

.post-text::first-letter {
    font-size: 30px;
    font-weight: bold;
    color: color.$blue-darkest;
  }

  .views {
      padding-top: 1rem;
      font-size: 20px;
  }