.compare {
    width: 80%;
    padding-bottom: 3rem;
}
.compare-wrapper {
    display: grid;
    grid-template-columns: 1fr; /* start with one column */
    grid-gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: auto;
    @media (max-width: 1299px) {
      /* if screen is at least 768px wide, use two columns */
      grid-template-columns: repeat(2, 1fr);
      align-items: left;
    }
    @media (max-width: 728px) {
      /* if screen is at least 768px wide, use two columns */
      grid-template-columns: repeat(1, 1fr);
      align-items: left;
    }
  
    @media (min-width: 1300px) {
      /* if screen is at least 992px wide, use three columns */
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .compare-card {
    overflow-y: hidden !important;
    display: flex;
    flex-direction: row;
    text-align: left;
    gap: 1rem;
    border: solid black 1px;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    transition: ease-in-out .2s;
    height: 170px;
    @media screen and (max-width: 500px) {
      height: 130px;
    }
    &:hover {
      background-color: #f5f5f5;
    }
 
    .comparison-details {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex-basis: 60%; /* 60% width */
      padding: 1.2rem;

      .compare-item {
        font-size: clamp(18px, 3vw, 22px);
        font-weight: 600;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3; 
        -webkit-box-orient: vertical;
      }
      
  
      .compare-price {
        font-size: 20px;
      }
    }
  
    .compare-image {
      flex-basis: 50%; /* 40% width */
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      height: 100%;
      .compare-image-src {
        width: 300px;
        /* Reduce the image height for smaller screens */
        height: 100%;
        @media (max-width: 550px) {
          width: 100%;
        }
        object-fit: cover;
      }
    }
  
    /* Media query for screens with width less than 768px */
    @media (max-width: 767px) {
      gap: 0.5rem;
      font-size: 16px;
      text-align: left;
  
      .comparison-details {
        flex-basis: 100%; /* Full width */
  
     
  
        .compare-price {
          font-size: 16px;
        }
      }
  
      .compare-image {
        flex-basis: 100%; /* Full width */
        img {
          /* Reduce the image height for smaller screens */
          height: 60px;
        }
      }
    }
  }
  
  